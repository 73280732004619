import request from '@/utils/request'

export function audit(data) {
  return request({
    url: 'crm/rewardPointTrans/audit',
    method: 'PUT',
    data
  })
}

export function deleteRule(data) {
  return request({
    url: `crm/rewardRule/${data.id}`,
    method: 'DELETE',
  })
}

export function addRule(data) {
  return request({
    url: 'crm/rewardRule',
    method: 'POST',
    data,
  })
}

export function editRule(data) {
  return request({
    url: 'crm/rewardRule',
    method: 'PUT',
    data,
  })
}

export function auditExchange(data) {
  return request({
    url: 'crm/rewardRule',
    method: 'PUT',
    data,
  })
}
